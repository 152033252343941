import { IGenericPageTemplateFields, IMediaReleaseArticlePageTemplateFields } from '../../../contentful-types'
import { getLineOfBusiness } from '../lineOfBusiness/lineOfBusiness'
import { Locale } from '../locale'
import { GenericBlogTemplate } from '../../blogBuilder/utils/types/theme'

export const adobePageAnalytics = (
  genericPageFields: IGenericPageTemplateFields | IMediaReleaseArticlePageTemplateFields | GenericBlogTemplate,
  locale: Locale,
  incorrectRegion: boolean
) => {
  const { alloyDataLayer } = genericPageFields

  const pageBrand = getLineOfBusiness(genericPageFields.slug).pageBrand

  const pageType =
    typeof alloyDataLayer?.fields?.pageType === 'string'
      ? alloyDataLayer?.fields?.pageType.split(' ').join('_').toLowerCase()
      : ''

  const siteSection =
    typeof alloyDataLayer?.fields?.siteSection === 'string'
      ? alloyDataLayer?.fields?.siteSection.split(' ').join('_').toLowerCase()
      : ''

  const primarySiteSection =
    typeof alloyDataLayer?.fields?.primarySiteSection === 'string'
      ? alloyDataLayer?.fields?.primarySiteSection.split(' ').join('_').toLowerCase()
      : ''

  const secondarySiteSection =
    typeof alloyDataLayer?.fields?.secondarySiteSection === 'string'
      ? alloyDataLayer?.fields?.secondarySiteSection.split(' ').join('_').toLowerCase()
      : ''

  const tertiarySiteSection =
    typeof alloyDataLayer?.fields?.tertiarySiteSection === 'string'
      ? alloyDataLayer?.fields?.tertiarySiteSection.split(' ').join('_').toLowerCase()
      : ''

  const quaternarySiteSection =
    typeof alloyDataLayer?.fields?.quaternarySiteSection === 'string'
      ? alloyDataLayer?.fields?.quaternarySiteSection.split(' ').join('_').toLowerCase()
      : ''

  const lineOfBusiness =
    typeof alloyDataLayer?.fields?.lineOfBusiness === 'object'
      ? alloyDataLayer?.fields?.lineOfBusiness.sort().join(',').toLowerCase()
      : ''

  const contentName =
    typeof alloyDataLayer?.fields?.contentName === 'string'
      ? alloyDataLayer?.fields?.contentName.split(' ').join('_').toLowerCase()
      : ''

  const pageProductCategory =
    typeof alloyDataLayer?.fields?.pageProductCategory === 'string'
      ? alloyDataLayer?.fields?.pageProductCategory.split(' ').join('_').toLowerCase()
      : ''

  // Checks if the siteSection has a value otherwise it passes an empty string
  const siteSections = {
    telus: 'telus',
    siteSection: siteSection,
    primarySiteSection: siteSection !== '' && primarySiteSection !== '' ? primarySiteSection : '',
    secondarySiteSection:
      siteSection !== '' && primarySiteSection !== '' && secondarySiteSection !== '' ? secondarySiteSection : '',
    tertiarySiteSection:
      siteSection !== '' && primarySiteSection !== '' && secondarySiteSection !== '' && tertiarySiteSection !== ''
        ? tertiarySiteSection
        : '',
    quaternarySiteSection:
      siteSection !== '' &&
      primarySiteSection !== '' &&
      secondarySiteSection !== '' &&
      tertiarySiteSection !== '' &&
      quaternarySiteSection !== ''
        ? quaternarySiteSection
        : '',
    contentName: siteSection !== '' && contentName !== '' ? contentName : '',
  }
  // Filters the siteSections object values of all empty strings and then joins them together with a chevron >
  const siteSectionList = Object.values(siteSections)
    .filter((item) => item !== '')
    .join('>')

  //Initializing the data layer on the window object
  window.adobeDataLayer = window.adobeDataLayer || []

  // Adding content to the adobeDataLayer array when the page loads
  window.adobeDataLayer.push({
    event: 'pageLoaded',
    eventInfo: "This and 'event' are removed when getState is called so won\t show in XDM",
    xdm: {
      web: {
        webPageDetails: {
          pageViews: {
            value: 1,
          },
          name: siteSectionList,
          URL: document.location.href,
          server: document.location.origin,
          siteSection: siteSections.siteSection,
          _telus: {
            brand: 'telus',
            pageBrand: pageBrand,
            customSiteSection: siteSections.siteSection,
            primarySiteSection: siteSections.primarySiteSection,
            secondarySiteSection: siteSections.secondarySiteSection,
            tertiarySiteSection: siteSections.tertiarySiteSection,
            quaternarySiteSection: siteSections.quaternarySiteSection,
            pageProductCategory: pageProductCategory,
            lob: lineOfBusiness,
            pageName: siteSectionList,
            customPageViews: 1,
            pageType: pageType,
            province: locale.province,
            pageLanguage: locale.language,
            siteGenerator: 'SiteBuilder2',
            tagManager: 'adobe_launch',
            webApp: 'web',
            dataLayerType: 'acdl',
            edgeServer: 'edge.adobedc.net',
          },
        },
        ...(incorrectRegion && {
          webInteraction: {
            _telus: {
              errorType: 'province_error',
              errorMessage: 'sorry_the_content_you_are_trying_to_view_is_not_available_in_your_province',
              errorDetails: 'content_not_available_in_province',
              errorInstances: 1,
            },
          },
        }),
      },
    },
  })
}
